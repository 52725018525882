import { get } from '@bigyouth/base'
import scrollManager from '../utils/scrollManager'
import SimpleBar from 'simplebar'
import emitter from '../utils/emitter'
import { STICKY_CALLER_ACTIONS } from './stickyContactCaller'

export const ACTIONS = {
  OPEN: 'CONTACT_OPEN',
}
export default {
  name: 'sticky-contact',

  isDesktop: true,

  ui: {
    'buttons[]': '[js-sticky-contact-button]',
    'contents[]': '[js-sticky-contact-inner]',
    'panels[]': '[js-sticky-contact-panel]',
  },

  events: [
    {
      target: 'buttons',
      on: 'click',
      handle: 'handleAction',
    },
    {
      on: 'click',
      handle (e) {
        // close sticky module on overlay click
        if (
          !e.target.classList.contains('sticky-contact-buttons__item') &&
          e.target.closest('.sticky-contact-panel__inner') == null
        ) {
          get(
            '[js-sticky-contact-panel].is-active:not([data-action="group"])'
          ).classList.remove('is-active')
          setTimeout(() => {
            this.el.classList.remove('is-active')
          }, 300)
          scrollManager.restart()

          this.ui.buttons.forEach(button => {
            button.classList.remove('is-active')
          })
        }
      },
    },
  ],

  handleAction (e) {
    const activeItem =
      get('[js-sticky-contact-button].is-active:not([data-action="group"])') ||
      null
    const activePanel =
      get('[js-sticky-contact-panel].is-active:not([data-action="group"])') ||
      null
    const action = e.target ? e.target.dataset.action : e.dataset.action
    const target = e.target ? e.target : e
    let targetIsActive = false

    // if active item/panel, remove
    if (activeItem && !activeItem.isEqualNode(target)) {
      activeItem.classList.remove('is-active')

      if (activePanel) {
        activePanel.classList.remove('is-active')
      }
    }

    // toggle active class on current elem + panel if mail or tel
    target.classList.toggle('is-active')
    targetIsActive = target.classList.contains('is-active')

    // toggle panel only if mail or tel
    if (action === 'mail' || action === 'tel') {
      get(`[js-sticky-contact-panel][data-action=${action}]`).classList.toggle(
        'is-active'
      )

      // toggle scroll
      if (targetIsActive) {
        const eventName = (action === 'mail') ? 'contact_email_formulaire' : 'contact_wcb_formulaire'
        window.dataLayer.push({
          'event': 'page_virtuelle',
          'event_name': eventName,
          'event_action': 'navigation',
          'event_page': document.location.pathname,
        })

        setTimeout(() => {
          scrollManager.stop()
        }, 300)
      } else {
        scrollManager.restart()
      }
    }

    targetIsActive ? this.el.classList.add('is-active') : setTimeout(() => {
      this.el.classList.remove('is-active')
    }, 300)
  },

  handleExternalAction (e) {
    this.handleAction(e)
  },

  componentDidMount () {
    this.isDesktop = window.innerWidth >= 768
    window.addEventListener('orientationchange', () => {
      this.isDesktop = window.innerWidth >= 768
    })

    // custom scroll in panels
    this.ui.contents.forEach(content => {
      /* eslint-disable-next-line */
      new SimpleBar(content)
    })

    // detect if touch device
    const hasTouch = 'ontouchstart' in window
    if (hasTouch) {
      document.body.classList.add('is-touch')
    }

    window.addEventListener(
      'touchstart',
      function onFirstTouch () {
        document.body.classList.add('is-touch')
        window.removeEventListener('touchstart', onFirstTouch, false)
      },
      false
    )

    // working with other components (stickyContactCaller)
    emitter.on(STICKY_CALLER_ACTIONS.MAIL, () => {
      this.handleExternalAction(
        get('[js-sticky-contact-button][data-action="mail"]')
      )
    })
  },
}
