import anime from 'animejs'

export default {
  name: 'newsletter',

  isOpen: false,

  ui: {
    mailInput: '[js-newsletter-mail]',
    moreContent: '[js-newsletter-more]',
  },

  events: [
    {
      target: 'mailInput',
      on: 'click',
      handle () {
        if (!this.isOpen) {
          const targets = [this.ui.moreContent]
          const height = this.ui.moreContent.offsetHeight

          this.ui.moreContent.classList.add('is-visible')

          anime({
            targets,
            height: ['0px', `${height}px`],
            duration: 500,
            easing: 'easeOutCubic',
            complete: () => {
              this.ui.moreContent.style.height = 'auto'
            },
          })

          this.isOpen = true
        }
      },
    },
  ],

  componentDidMount () {},
}
