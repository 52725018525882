// global analytics

import 'intersection-observer'
import { getAll, createComponent } from '@bigyouth/base'
import 'promise-polyfill/src/polyfill'

import 'what-input/dist/what-input'

import './polyfills'
import './assets'

import './style/styles-global.scss'

import 'lazysizes'
import objectFitImages from 'object-fit-images'

import * as features from './features/exports/global'

Object.values(features).forEach(createComponent)

objectFitImages()

// remove preload class on body (prevent flash of transitions when page is loading)
document.addEventListener('DOMContentLoaded', function () {
  document.body.classList.remove('preload')
})

// data layer footer social
getAll('[js-footer-social]').forEach(social => {
  social.addEventListener('click', e => {
    window.dataLayer.push({
      'event': 'social',
      'event_name': social.dataset.name,
      'event_action': 'sortie',
      'event_page': document.location.pathname,
    })
  })
})
