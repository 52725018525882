import scrollManager from '../utils/scrollManager'
import { get, getAll } from '@bigyouth/base'

export default {
  name: 'header',

  navOpen: false,

  ui: {
    langToggle: '[js-header-lang-toggle]',
    'subnavToggle[]': '[js-header-subnav-toggle]',
    burger: '[js-header-burger]',
    nav: '[js-header-nav]',
    'subnavs[]': '[js-header-subnav]',
    'backnavs[]': '[js-header-back]',
    'account[]': '[js-header-account]',
  },

  events: [
    {
      target: 'langToggle',
      on: 'click',
      handle (e) {
        e.target.closest('ul').classList.toggle('is-open')
      },
    },
    {
      target: 'account',
      on: 'click',
      handle (e) {
        window.dataLayer.push({
          'event': 'clic',
          'event_name': 'lien_espace_client',
          'event_action': 'clic',
          'event_page': document.location.pathname,
        })
      },
    },
    {
      target: 'burger',
      on: 'click',
      handle (e) {
        e.target.classList.toggle('is-active')
        this.ui.nav.classList.toggle('is-active')

        if (!e.target.classList.contains('is-active')) {
          this.ui.subnavs.forEach(subnav => {
            this.closeSubnav(subnav)
          })

          scrollManager.restart()
          this.navOpen = false
        } else {
          // timeout: avoid jump on IOS
          setTimeout(() => {
            scrollManager.stop()
          }, 700)
          this.navOpen = true
        }
      },
    },
    {
      target: 'backnavs',
      on: 'click',
      handle (e) {
        const parent = e.target.closest('[js-header-subnav]')

        this.closeSubnav(parent)
      },
    },
    {
      target: 'subnavToggle',
      on: 'click',
      handle (e) {
        this.toggleNav(e, 'open')
      },
    },
    {
      target: 'subnavToggle',
      on: 'mouseover',
      handle (e) {
        if (e.target.dataset.level !== '1') {
          this.toggleNav(e, 'open')
        }
      },
    },
  ],

  componentDidMount () {
    // close nav if click on overlay (only desktop mode)
    if (window.innerWidth >= 1024) {
      document.body.addEventListener('click', e => {
        // if click on overlay && menu active
        if (!e.target.closest('.subnav') && !e.target.classList.contains('main-nav__link') && this.navOpen) {
          this.closeAll()
        }
      })
    }
  },

  closeAll () {
    this.ui.subnavs.forEach(subnav => {
      this.closeSubnav(subnav)
      this.navOpen = false
      scrollManager.restart()
      document.body.classList.remove('has-overlay')
    })
  },

  toggleNav (e, type) {
    const level = e.target.dataset.level
    const subnavTarget = e.target.nextElementSibling

    if (type === 'toggle') {
      subnavTarget.classList.toggle('is-active')
      e.target.classList.toggle('is-active')
    } else {
      subnavTarget.classList.add('is-active')
      e.target.classList.add('is-active')
    }

    // close all submenus or siblings elements
    getAll('[js-header-subnav]', e.target.closest('ul')).forEach(subnav => {
      if (!subnav.isEqualNode(subnavTarget)) {
        if (level === '1') {
          this.closeSubnav(subnav, false)
        } else {
          this.closeSubnav(subnav, true)
        }
      }
    })

    // 1st subnav
    if (level === '1') {
      if (e.target.classList.contains('is-active')) {
        scrollManager.stop()
        document.body.classList.add('has-overlay')
        this.el.classList.add('has-overflow')
        this.navOpen = true
      } else {
        scrollManager.restart()
        document.body.classList.remove('has-overlay')

        setTimeout(() => {
          this.el.classList.remove('has-overflow')
        }, 500)

        this.navOpen = false
      }
    }
  },

  closeSubnav (subnav, removeCurrentPage) {
    subnav.classList.remove('is-active')
    subnav.previousElementSibling.classList.remove('is-active')
    get('.subnav__inner', subnav).scroll(0, 0)

    if (removeCurrentPage && subnav.classList.contains('is-active2')) {
      subnav.classList.remove('is-active2')
      subnav.previousElementSibling.classList.remove('is-active2')
    }
  },
}
