import emitter from '../utils/emitter'

export const STICKY_CALLER_ACTIONS = {
  MAIL: 'MAIL',
}

export default {
  name: 'sticky-contact-caller',

  events: [
    {
      on: 'click',
      handle () {
        const action = this.el.dataset.action

        if (action === 'mail') {
          emitter.trigger(STICKY_CALLER_ACTIONS.MAIL)
        }
      },
    },
  ],
}
