import onResize from '../utils/onResize'

export default {
  name: 'full-height-mobile',

  componentDidMount () {
    this.off = onResize(this.onResize.bind(this))

    this.onResize()
    this.calcHeight()

    window.addEventListener('orientationchange', () => {
      this.calcHeight()
    })
  },

  componentWillUnmount () {
    this.off()
  },

  onResize () {
    this.calcHeight()
  },

  calcHeight () {
    let vh =
      window.innerWidth < 1024
        ? (window.innerHeight - 60) * 0.01
        : window.innerHeight * 0.01

    this.el.style.setProperty('--vh', `${vh}px`)
  },
}
