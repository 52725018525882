import withScroll from '../hoc/withScroll'

export default withScroll({
  name: 'depth-scroll',

  scrolled25: false,
  scrolled50: false,
  scrolled75: false,
  scrolled100: false,

  componentDidMount () {},

  onScroll ({ y }) {
    var winHeight =
      window.innerHeight ||
      (document.documentElement || document.body).clientHeight
    var docHeight = document.body.offsetHeight
    var scrollTop =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop
    var trackLength = docHeight - winHeight
    var percentScrolled = Math.floor((scrollTop / trackLength) * 100)

    if (typeof ga !== 'undefined') {
      if (percentScrolled >= 25 && !this.scrolled25) {
        ga('gtm5.send', 'event', window.location.pathname, 'scroll', '25%')
        this.scrolled25 = true
      } else if (percentScrolled >= 50 && !this.scrolled50) {
        ga('gtm5.send', 'event', window.location.pathname, 'scroll', '50%')
        this.scrolled50 = true
      } else if (percentScrolled >= 75 && !this.scrolled75) {
        ga('gtm5.send', 'event', window.location.pathname, 'scroll', '75%')
        this.scrolled75 = true
      } else if (percentScrolled >= 100 && !this.scrolled100) {
        ga('gtm5.send', 'event', window.location.pathname, 'scroll', '100%')
        this.scrolled100 = true
      }
    }
  },
})
